import * as Yup from 'yup';

const keyIdRegExp = /^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$/;
const privateKeyRegExp = /^0x[0-9a-fA-F]{64}$/;
const baseChainHttpRegExp = /^(?:(?:https?):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/;
const baseChainWsRegExp = /^(?:(?:wss?):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/;
const validiumNameRegExp = /^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$/;

export const REQUIRED_FIELD_ERROR_MESSAGE = 'Field is required';
export const KEY_ID_ERROR_VALIDATION_MESSAGE = `Key id is not valid. Key id must contain at most 63 characters, contain only lowercase alphanumeric characters or '-', start with an alphabetic character, and end with an alphanumeric character.`;
export const PRIVATE_KEY_ERROR_VALIDATION_MESSAGE = `Private key should be in correct format: '0x' prefix + 64-length hexadecimal string`;
export const VALIDIUM_ID_ERROR_VALIDATION_MESSAGE = `Validium id is not valid. Validium id must contain at most 63 characters, contain only lowercase alphanumeric characters or '-', start with an alphabetic character, and end with an alphanumeric character.`;
export const BASE_CHAIN_HTTP_ERROR_VALIDATION_MESSAGE = `Base chain http is not valid. The HTTP url must start with 'http://' or 'https://'.`;
export const BASE_CHAIN_WS_ERROR_VALIDATION_MESSAGE = `Base chain ws is not valid. The WS url must start with 'ws://' or 'wss://'.`;

export const LoginSchema = Yup.object({
  username: Yup.string().email('Email is not valid').required(REQUIRED_FIELD_ERROR_MESSAGE),
  password: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
});

export const UpdatePasswordSchema = Yup.object({
  password: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  confirmPassword: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .test('Equals with password field', `Passwords don't match`, (value, context) => {
      const passwordFieldValue = context.from && context.from[0].value.password;

      return passwordFieldValue === value;
    }),
});

export const ResetPasswordSchema = Yup.object({
  email: Yup.string().email('Email is not valid').required(REQUIRED_FIELD_ERROR_MESSAGE),
});

export const KeyCreateSchema = Yup.object({
  id: Yup.string().matches(keyIdRegExp, KEY_ID_ERROR_VALIDATION_MESSAGE).required(REQUIRED_FIELD_ERROR_MESSAGE),
  privateKey: Yup.string().matches(privateKeyRegExp, PRIVATE_KEY_ERROR_VALIDATION_MESSAGE),
});

export const ValidiumCreationSchema = Yup.object({
  validiumId: Yup.string()
    .matches(validiumNameRegExp, VALIDIUM_ID_ERROR_VALIDATION_MESSAGE)
    .required(REQUIRED_FIELD_ERROR_MESSAGE),
  baseChainHTTP: Yup.string()
    .matches(baseChainHttpRegExp, BASE_CHAIN_HTTP_ERROR_VALIDATION_MESSAGE)
    .required(REQUIRED_FIELD_ERROR_MESSAGE),
  baseChainWS: Yup.string()
    .matches(baseChainWsRegExp, BASE_CHAIN_WS_ERROR_VALIDATION_MESSAGE)
    .required(REQUIRED_FIELD_ERROR_MESSAGE),
  l1ChainId: Yup.number().min(0).required(REQUIRED_FIELD_ERROR_MESSAGE),
  l2ChainId: Yup.number().min(0).required(REQUIRED_FIELD_ERROR_MESSAGE),
  aggregatorKeyId: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  dacNodeKeyId: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  deployerKeyId: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  sequencerKeyId: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
});
