export enum ErrorType {
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
  FORBIDDEN = 'FORBIDDEN',
  UNAVAILABLE = 'UNAVAILABLE',
  CLIENT_RESPONSIBLE = 'CLIENT_RESPONSIBLE',
  VALIDATION = 'VALIDATION',
  UNKNOWN = 'UNKNOWN',
  IGNORED = 'IGNORED',
}

export enum QueryKeys {
  VALIDIUMS = 'VALIDIUMS',
  KEYS = 'KEYS',
}
